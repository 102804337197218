import { ChangeDetectorRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Calendar } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../services/popup.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { auditTime, delay, takeUntil, tap } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
import * as moment from 'moment';
import { UnsubscribeAbstract } from '../../abstracts/unsubscribe.abstract';
import { MobileDebugService } from '../../../services/mobile-debug.service';
import { fromPromise } from 'rxjs/internal-compatibility';

export abstract class WaitingRoomAbstractComponent
  extends UnsubscribeAbstract
  implements OnInit, OnDestroy {

  private untilFinishedJoinTime = new Subject();
  public displayedColumns: string[] = ['name', 'date', 'time'];
  public calendarData: Calendar[] = [];

  public minutesText = false;
  public monthText = false;
  public dayText = false;
  public serverInfo;
  public serverInfoStartTime;

  public timerId;
  public time;
  public date;
  public month;
  public isTimeOut = false;
  public dateOut = false;
  public colorPrimary;
  public colorSecondary;

  isEventEnded = false;
  isEventStarted = false;
  isMobile: boolean;
  isLoginLoading = true;

  protected constructor(public userService: UserService,
                        protected router: Router,
                        public translate: TranslateService,
                        protected cdr: ChangeDetectorRef,
                        public popupService: PopupService,
                        protected deviceDetectorService: DeviceDetectorService,
                        protected ngZone: NgZone,
                        private logsService: MobileDebugService) {
    super();

    this.isMobile = this.deviceDetectorService.isMobile();
  }

  ngOnInit() {

    // This proves more reliable, because subscription to router events stops working after the page refresh in videochat
    if (sessionStorage.wasInTheCall) { // Workaround for disabled 'start/join' button after browser 'back'
      sessionStorage.removeItem('wasInTheCall');
      location.reload();
    }
    // to update now for server
    this.userService.getUserSocket();
    this.userService.setPage('waitingRoom');
    this.userService.observeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {

       this.logsService.add(data);
       this.serverInfo = data;
       this.colorPrimary=data.colorPrimary;
       this.colorSecondary=data.colorSecondary;

       sessionStorage.setItem('colorSecondary',this.colorSecondary);

        if (this.serverInfo.start) {
          this.isLoginLoading = false;
          this.serverInfoStartTime = new Date(moment(this.serverInfo.start).valueOf()).toISOString();
        }

        this.calendarData = data.schedule ? data.schedule : [];

        this.initializeTimer();
      });
    setTimeout(() => {
      this.detectBrowser();
    }, 1000);
  }


  detectBrowser() {
    if (navigator.userAgent.includes('Firefox')) {
      const version = navigator.userAgent.split('Firefox/')[1].split(' ')[0].split('.')[0];
      if (+version < 66) {
        this.popupService.openErrorNotification(this.translate.instant('popups.browserVersionWarning'));
      }
    } else if (navigator.userAgent.includes('Chrome')) {
      const version = navigator.userAgent.split('Chrome/')[1].split(' ')[0].split('.')[0];
      if (+version < 72) {
        this.popupService.openErrorNotification(this.translate.instant('popups.browserVersionWarning'));
      }
    }
  }

  initializeTimer() {
    this.untilFinishedJoinTime.next();
    // We use 'moment' instead of Date.parse, because Date.parse returns 'NaN' in Safari
    const currentDate = moment(this.serverInfo.now).valueOf();
    const meetingDate = moment(this.serverInfo.start).valueOf();

    const diffTime = meetingDate - currentDate;
    if (this.serverInfo.now) {
      interval(1000)
        .pipe(
          takeUntil(this.unsubscribe$),
          takeUntil(this.untilFinishedJoinTime)
        )
        .subscribe(times => {
          times *= 1000;

          if (diffTime - times + +this.serverInfo.joinTime > 0) {
            if (diffTime - times >= 0) {
              this.isEventEnded = false;
              this.isEventStarted = false;
              this.setTimeOnPage(
                moment.duration(diffTime - times).months(),
                moment.duration(diffTime - times).days(),
                moment.duration(diffTime - times).hours(),
                moment.duration(diffTime - times).minutes(),
                moment.duration(diffTime - times).seconds()
              );
            } else {
              this.setOnTime();
              this.isEventStarted = true;
              this.isEventEnded = false;
            }
          } else {
            this.isEventEnded = true;
            this.isEventStarted = true;
            this.untilFinishedJoinTime.next();
          }

          this.cdr.detectChanges();
        });
    }
  }

  setOnTime() {
    this.time = '00:00';
    this.minutesText = true;
  }

  numToString = (num: Number) => {
    return num.toString().padStart(2, '0');
  }

  getOpponentName() {
    const opponent = this.serverInfo.role === 'doctor' ? this.serverInfo.patient : this.serverInfo.doctor;
    return opponent.firstName + ' ' + opponent.lastName;
  }

  setTimeOnPage(month, days, hrs, min, sec) { // set Time on html
    this.month = month ? month.toString() : '';
    this.date = days ? days.toString() : '';
    const hours = this.numToString(hrs) + ':';
    this.time = `${hrs ? hours : ''}${this.numToString(min)}:${this.numToString(sec)}`;
    this.dayText = !!days;
    this.minutesText = !hrs;
    this.monthText = !!month;
  }

  playDummySound() {
    // Workaround for iOS browsers, which won't play any audio until one is played on user's gesture
    const audioWorkaround = new Audio('/assets/sounds/enterRoom.mp3');
    audioWorkaround.volume = 0; // Because it's a workaround
    audioWorkaround.play()
      .then(() => {
        console.log('audioWorkaround played successfully');
      })
      .catch((error) => {
        console.log('audioWorkaround play error', error);
      });
  }

  logIn() {
    this.isLoginLoading = true;
    this.cdr.detectChanges();

    const room = this.userService.getRoomId();
    const token = this.userService.getToken();

    this.playDummySound();

    this.router.navigate(['/room' + '/' + room + '/' + token]);

    setTimeout(() => {
      if ((document as any).querySelector('app-waiting-room')) {
        (window as any).location = '/room' + '/' + room + '/' + token;
      }
    }, 3000);
  }


  ngOnDestroy() {
    this.untilFinishedJoinTime.complete();
    super.ngOnDestroy();
  }
  checkColor(color) {
    return true;
  }

}
