import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Role, User } from '../models/user';
import io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from './popup.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

const socket = io(environment.socketUrl);
const avatarDefault = '../../assets/icons/user.png';
const logoDefault = '../../assets/img/webprax_f2f_logo.png'
enum EventTypes {
  leave = 'leave',
  join = 'join',
  events = 'events',
  start = 'start',
}

/// localStorage.debug = '*';
const serverFormat = 'YYYY-MM-DD hh:mm:ss';

export type Pages = 'waitingRoom' | 'videoChat' | 'settings' | 'thanks' | 'chat' | 'notes' | 'documents' | 'sketch';
@Injectable()
export class UserService {

  private baseUrl: string;
  private user = new BehaviorSubject(new User);
  private errorPopupIsOpen: boolean;
  private callSessionStatus$ = new Subject<boolean>();

  public ids: string;
  public roomId: string;
  public token: string;
  public isMirrored = false;
  public role = new BehaviorSubject<Role>(undefined);
  public page = new BehaviorSubject<Pages>(undefined);
  public socket = socket;
  public io = io;

  public observeLogout = new Subject<boolean>();

  private static isValidURL(str) {
    const urlRegex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return urlRegex.test(str);
  }
  private static isValidColorHash(colorHash){
    const colorRegex = /#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g;
    return colorHash!=null && colorRegex.test(colorHash);
  }

  private static serverTimeToISO(date: string) {
    return moment(date, serverFormat).toISOString();
  }

  constructor(private _ngZone: NgZone,
              public translate: TranslateService,
              public popupService: PopupService,
              private router: Router) { // For local build use ng serve -c=local

    const parsedURL: string = environment.baseUrl
      .replace('https://', '')
      .replace('http://', ''); // Failsafe in case anyone changes 'http' to 'https' or other way around in configs

    this.ids = window.location.href.replace(parsedURL, '')
      .replace('localhost:4200/', '')
      .replace('https://', '')
      .replace('http://', '')
      .replace('room/', '')
      .replace('settings/', '');

    try {
      this.roomId = this.ids.match(/[^/]*/)[0];
      this.token = this.ids.match(/\/([^/]*)$/)[1];
    } catch (e) {
      // workaround for PROD /thanks page
      console.log('Problem parting url in UserService', e);
    }

    this.baseUrl = environment.socketUrl + 'events/read/' + this.ids;
    this.errorPopupIsOpen = false;

    socket.on(EventTypes.events, (data) => {

      if (data && data.type === 'Error' && data.status === 404) { // Wrong room case
        if (!this.errorPopupIsOpen) {
          this.errorPopupIsOpen = true;
          this._ngZone.run(() => {
            this.popupService.openErrorDialog(this.translate.instant('popups.roomError')).then(() => {
              this.logOut(this.role.getValue());
              this.setPage('thanks');
              sessionStorage.clear();
              this.router.navigate(['/thanks']);
            }).catch((error: any) => {
              console.log('log out error ', error);
            });
          });
        }
      } else if (!this.getUser().role) {
        this.loadUser(data);
      } else {
        this.updateData(data);
      }
    });

    socket.on(EventTypes.join, (data) => {
      this.callSessionStatus$.next(true);
      this.updateData(data);
    });

    socket.on(EventTypes.leave, (data) => {
      this.callSessionStatus$.next(false);
      this.updateData(data);
    });
  }

  get callStatus$(): Observable<boolean> {
    return this.callSessionStatus$.asObservable();
  }

  getUserSocket() {
    console.log('get socket:: events');
    socket.emit(EventTypes.events, {
      room: this.roomId,
      token: this.token
    });
  }

  logIn(role: Role) {
    console.log('log in:: join | role', role);
    socket.emit(EventTypes.join, {
      room: this.roomId,
      token: this.token,
      role,
    });
  }

  inviteToTheRoom(role: Role) {
    console.log('invite to the room:: start');
    socket.emit(EventTypes.start, {
      room: this.roomId,
      token: this.token,
      role,
    });
  }

  logOut(role: Role) {
    console.log('log out:: leave');
    socket.emit(EventTypes.leave, {
      room: this.roomId,
      token: this.token,
      role,
    });
    this.observeLogout.next(true);
  }

  toggleMirrorVideo() {
    this.isMirrored = !this.isMirrored;
  }

  getRoomId() {
    return this.roomId;
  }

  getToken() {
    return this.token;
  }

  getUser(): User {
    return this.user.value;
  }

  getDoctorName() {
    return this.user.value.doctor.firstName + ' ' + this.user.value.doctor.lastName;
  }

  observeRole() {
    return this.role.asObservable();
  }

  observeUser() {
    return this.user.asObservable();
  }

  observePage() {
    return this.page.asObservable();
  }

  setPage(page: Pages) {
    this.page.next(page);
  }

  checkColorHash(){
    console.log("teeeeeeest");
    return false;

  }

  private loadUser(data) {
    this.role.next(data.role);
    const isDoctor = data.role === 'doctor';
    const doctorAvatar = UserService.isValidURL(data.doctor.avatar) ? data.doctor.avatar : avatarDefault;
    const patientAvatar = UserService.isValidURL(data.patient.avatar) ? data.patient.avatar : avatarDefault;
    const logoUrl = UserService.isValidURL(data.logoUrl) ? data.logoUrl : logoDefault;

    this.user.next(User.fromJson(
      {

        connections: data.connections,
        doctor: {
          avatar: doctorAvatar,
          email: data.doctor.email,
          firstName: data.doctor.firstName,
          lastName: data.doctor.lastName,
          loggedIn: data.doctor.loggedIn
        },
        now: moment(data.now).toISOString(),
        joinTime: data.joinTime,
        start: moment(data.start).toISOString(),
        participants: data.participants,
        patient: {
          avatar: patientAvatar,
          email: data.patient.email,
          firstName: data.patient.firstName,
          lastName: data.patient.lastName,
          loggedIn: data.patient.loggedIn
        },
        role: data.role,
        schedule: data.schedule.map(i => {
            return {
              ...i,
              start: moment(i.start).toISOString(),
            };
          }
        ),
        status: data.status,
        title: data.title,
        firstName: (isDoctor ? data.doctor.firstName : data.patient.firstName) || 'FirstName',
        lastName: (isDoctor ? data.doctor.lastName : data.patient.lastName) || 'LastName',
        avatar: isDoctor ? doctorAvatar : patientAvatar,
        callerAvatar: isDoctor ? patientAvatar : doctorAvatar,
        logoUrl:logoUrl,
        colorPrimary:UserService.isValidColorHash(data.colorPrimary) ? data.colorPrimary: null,
        colorSecondary:UserService.isValidColorHash(data.colorSecondary) ? data.colorSecondary: null,
        chat_access:data.chat_access,
        documents_access:data.documents_access,
        notes_access:data.notes_access,
        sketch_access:data.sketch_access

      }));
  }

  private updateData(data) {
    if (data.participants > 2) {
      return;
    }
    const isDoctor = this.getUser().role === 'doctor';

    const doctorAvatar = UserService.isValidURL(data.doctor.avatar) ? data.doctor.avatar : avatarDefault;
    const patientAvatar = UserService.isValidURL(data.patient.avatar) ? data.patient.avatar : avatarDefault;
    const logoUrl = UserService.isValidURL(data.logoUrl) ? data.logoUrl : logoDefault;


    this.user.next(User.fromJson(
      {
        connections: data.connections,
        doctor: {
          avatar: doctorAvatar,
          email: data.doctor.email,
          firstName: data.doctor.firstName,
          lastName: data.doctor.lastName,
          loggedIn: data.doctor.loggedIn
        },
        now: moment(data.now).toISOString(),
        joinTime: data.joinTime,
        start: moment(data.start).toISOString(),
        participants: data.participants,
        patient: {
          avatar: patientAvatar,
          email: data.patient.email,
          firstName: data.patient.firstName,
          lastName: data.patient.lastName,
          loggedIn: data.patient.loggedIn
        },
        role: this.getUser().role,
        schedule: data.schedule ? data.schedule.map(i => {
            return {
              ...i,
              start: moment(i.start).toISOString(),
            };
          }
        ) : this.getUser().schedule,
        status: data.status,
        title: data.title,
        firstName: (isDoctor ? data.doctor.firstName : data.patient.firstName) || 'FirstName',
        lastName: (isDoctor ? data.doctor.lastName : data.patient.lastName) || 'LastName',
        avatar: isDoctor ? doctorAvatar : patientAvatar,
        callerAvatar: isDoctor ? patientAvatar : doctorAvatar,
        logoUrl: logoUrl,
        colorPrimary: UserService.isValidColorHash(data.colorPrimary) ? data.colorPrimary : null,
        colorSecondary:UserService.isValidColorHash(data.colorSecondary) ? data.colorSecondary: null,
       chat_access:data.chat_access,
        documents_access:data.documents_access,
        notes_access:data.notes_access,
        sketch_access:data.sketch_access
      }));
  }

}
